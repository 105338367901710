import React, { useRef } from "react"
import Slider from "react-slick"
import GTMBannerMob from "../images/GTM-p2-event-hero-banner-mobile.jpg"
import GTMBanner from "../images/GTM-p2-event-hero-banner.jpg"
import P2HeroBannerNew from "../video/P2-hero-banner-new.mp4"
import HomeMobileBanner from "../video/home-mobile-banner.mp4"
export default function HeroBannerSlider() {
  const sliderRef = useRef(null)
  const sectionRef = useRef(null)

  // useEffect(() => {
  //     const handleScroll = () => {
  //         const scrollTop = window.scrollY;
  //         const sectionOffset = sectionRef.current.offsetTop;
  //         const sectionHeight = sectionRef.current.clientHeight;
  //         const scrollPositionInSection = scrollTop - sectionOffset;

  //         if (scrollPositionInSection >= 0 && scrollPositionInSection <= sectionHeight) {
  //             const totalSlides = sliderRef.current.props.children.length;
  //             const currentIndex = (scrollPositionInSection / sectionHeight) * totalSlides;
  //             sliderRef.current.slickGoTo(Math.round(currentIndex));
  //         }
  //     };

  //     window.addEventListener('scroll', handleScroll);
  //     return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 0,
    pauseOnHover: false,
    lazyLoad: true,
    loop: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <ul style={{ display: "flex", gap: "20px" }}> {dots} </ul>
    ),

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <div
      className="slider-section"
      ref={sectionRef}
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <Slider {...settings} ref={sliderRef}>
        <div className="each-slider">
          <div className="banner-cover">
            <video
              width="100%"
              playsInline="playsinline"
              muted="muted"
              preload="yes"
              autoPlay="autoplay"
              loop="loop"
              className="video-js BiggerScreen"
              data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
            >
              <source src={P2HeroBannerNew} type="video/mp4" />
            </video>
            <video
              width="100%"
              playsInline="playsinline"
              muted="muted"
              preload="yes"
              autoPlay="autoplay"
              loop="loop"
              className="video-js SmallerScreen"
              data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
            >
              <source src={HomeMobileBanner} type="video/mp4" />
            </video>
          </div>
          <div className="home-banner-content">
            <div className="container">
              <div className="content-wrap">
                <h1>Your AI-powered growth engine!</h1>
                <p>
                  We combine the creativity of our experts with the optimization
                  of AI to fuel rapid yet sustainable growth for your company
                  navigating a digital transformation.
                </p>
                <a href="/ai-growth-tech/">Explore</a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="each-slider event-slider">
          <div className="banner-cover ">
            <picture>
              <source
                media="(max-width: 767.98px)"
                srcset={GTMBannerMob}
                alt="Bellhops in decreasing their CPL by 57% and increase their conversions by 3.75X."
              />
              <img
                src={GTMBanner}
                alt="Bellhops in decreasing their CPL by 57% and increase their conversions by 3.75X."
              />
            </picture>
          </div>
          <div className="home-banner-content">
            <div className="container">
              <div className="content-wrap">
                <h1>Paella Party hosted by GTM Leader Society</h1>
                <p>
                  <span>Sunday, May 19</span>
                  <br />
                  1:00 PM - 4:00 PM PDT
                </p>
                <p>
                  <span>Hillsborough</span>
                  <br />
                  California
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </Slider>
    </div>
  )
}
