import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import ReactImageVideoLightbox from "react-image-video-lightbox"
import Layout from "../components/layout"
import Slider from "../components/slider"
import GTMBanner from "../images/GTM-p2-web-hero-banner.jpg"
import GTMBannerMob from "../images/GTM-p2-web-hero-banner-mobile.jpg"
import Amex from "../images/AMEX.svg"
import AWS from "../images/AWS.svg"
import Acalvio from "../images/Acalvio.svg"
import Airbnb from "../images/Airbnb-1.svg"
import blogthum1 from "../images/Beyond-the-Hype-AI's-Actual-Impact-on-Four-Key-Industries.png"
import Cohere from "../images/Cohere.svg"
import GD from "../images/GD.svg"
import GoogleCloud from "../images/GoogleCloud.svg"
import Lenovo from "../images/Lenovo.svg"
import OpenPrise from "../images/OpenPrise.svg"
import bg from "../images/Parallax1.png"
import bg1 from "../images/Parallax2.png"
import bg2 from "../images/Parallax3.png"
import blogthum2 from "../images/Top-Barriers-to-Proactive-AI-Adoption.jpg"
import blogthum3 from "../images/Why-is-it-important-to-optimize-your-landing-pages.jpg"
import Artboard from "../images/artboard.png"
import awards4 from "../images/awards-four.png"
import awards1 from "../images/awards-one.png"
import awards3 from "../images/awards-three.png"
import awards2 from "../images/awards-two.png"
import BigBang from "../images/awards/Big-Bang.svg"
import DesignAwards from "../images/awards/Design-Awards.svg"
import IAC from "../images/awards/IAC.svg"
import Iamia from "../images/awards/Iamia.svg"
import WMA from "../images/awards/WMA.svg"
import Webawards from "../images/awards/Webawards.svg"
import dataIntegration from "../images/data-integration.png"
import RichDataInsights from "../images/rich-data-insights.png"
import img1 from "../images/strategic-storytelling.webp"
import img2 from "../images/converting-audience.webp"
import img3 from "../images/engage-convert.webp"
import "../styles/404.css"
import P2HeroBannerNew from "../video/P2-hero-banner-new.mp4"
import HomeMobileBanner from "../video/home-mobile-banner.mp4"
import HeroBannerSlider from "../components/hero-banner-slider"

const HomePage = ({ siteTitle }) => {
  const [isOpen, setOpen] = useState(false)
  const [isCaseStudyOneOpen, setIsCaseStudyOneOpen] = useState(false)
  const [isCaseStudyTwoOpen, setIsCaseStudyTwoOpen] = useState(false)

  const handleCaseStudyOneOpen = () => {
    setIsCaseStudyOneOpen(true)
  }
  const handleCaseStudyOneClose = () => {
    setIsCaseStudyOneOpen(false)
  }
  const handleCaseStudyTwoOpen = () => {
    setIsCaseStudyTwoOpen(true)
  }
  const handleCaseStudyTwoClose = () => {
    setIsCaseStudyTwoOpen(false)
  }

  useEffect(() => {
    // Check if we're running in the browser environment
    if (typeof window !== "undefined") {
      // Check if the user agent contains "Safari" but not "Chrome"
      const isSafari = /^((?!chrome|android).)*Safari/i.test(
        navigator.userAgent
      )

      // Check if the browser is running on macOS or iOS
      const isMacOrIOS = /(Mac|iPhone|iPod|iPad)/.test(navigator.platform)

      // Combine the conditions
      if (isSafari && isMacOrIOS) {
        document.documentElement.classList.add("safari-mac")
      }
    }
  }, []) // Empty dependency array ensures the effect runs only once after component mount

  // const heroslidersettings = {
  //   dots: true,
  //   autoplay: false,
  //   arrows: false,
  //   autoplaySpeed: 0,
  //   pauseOnHover: false,
  //   loop: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   appendDots: dots => (
  //     <ul style={{ display: "flex", gap: "20px" }}> {dots} </ul>
  //   ),

  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // }

  const settings = {
    dots: true,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 0,
    pauseOnHover: false,
    loop: false,
    speed: 500,
    slidesToShow: 1,
    appendDots: dots => (
      <ul style={{ display: "flex", gap: "20px" }}> {dots} </ul>
    ),

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Keep on growing | Growth Marketing Agency | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div>
          <div id="HomeHeroBannerSlider">
            <div className="slider-wrapper">
              <div className="homepage-slider">
                  {/* <div className="each-slider">
                    <div className="banner-cover">
                      <video
                        width="100%"
                        playsInline="playsinline"
                        muted="muted"
                        preload="yes"
                        autoPlay="autoplay"
                        loop="loop"
                        className="video-js BiggerScreen"
                        data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
                      >
                        <source src={P2HeroBannerNew} type="video/mp4" />
                      </video>
                      <video
                        width="100%"
                        playsInline="playsinline"
                        muted="muted"
                        preload="yes"
                        autoPlay="autoplay"
                        loop="loop"
                        className="video-js SmallerScreen"
                        data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
                      >
                        <source src={HomeMobileBanner} type="video/mp4" />
                      </video>
                    </div>
                    <div className="home-banner-content">
                      <div className="container">
                        <div className="content-wrap">
                          <h1>Your AI-powered growth engine!</h1>
                          <p>
                            We combine the creativity of our experts with the
                            optimization of AI to fuel rapid yet sustainable
                            growth for your company navigating a digital
                            transformation.
                          </p>
                          <a href="/ai-growth-tech/">Explore</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="each-slider">
                    <div className="banner-cover">
                      <picture>
                        <source
                          media="(max-width: 991.98px)"
                          srcset={GTMBannerMob}
                          alt="Bellhops in decreasing their CPL by 57% and increase their conversions by 3.75X."
                        />
                        <img
                          src={GTMBanner}
                          alt="Bellhops in decreasing their CPL by 57% and increase their conversions by 3.75X."
                        />
                      </picture>
                    </div>
                    <div className="home-banner-content">
                      <div className="container">
                        <div className="content-wrap">
                          <h1>Paella Party hosted by GTM Leader Society</h1>
                          <p><span>Sunday, May 19</span><br/>
                            1:00 PM - 4:00 PM  
                          </p>
                          <p><span>Hillsborough</span><br/>
                            California  
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <HeroBannerSlider/>
              </div>
            </div>
          </div>
          <div className="homepage-parallax-wrap">
            <div
              className="homeparallax"
              style={{ backgroundImage: `url(${bg})` }}
            >
              <div className="holder">
                <div className="container">
                  <div className="content-wrapper">
                    <div className="left-content">
                      <div className="animation-text">
                        <div>
                          <h2>
                            Strategy. <br />
                            Storytelling. <br />
                            Results.
                          </h2>
                        </div>
                        <div className="decp">
                          <p>
                            Our team of expert creators use AI in visual
                            storytelling, innovation and optimization to develop
                            creative content for our clients’ businesses to
                            stand out from their competitors and resonate deeply
                            with their target audiences.
                          </p>
                        </div>
                        <div className="logo-wrap">
                          <div className="each-logo">
                            <img src={AWS} alt="AWS" />
                          </div>
                          <div className="each-logo">
                            <img src={Lenovo} alt="Lenovo" />
                          </div>
                          <div className="each-logo">
                            <img src={GoogleCloud} alt="Google Cloud" />
                          </div>
                        </div>
                        <div className="page-links">
                          <div className="each-page-links">
                            <a href="/web-design-and-development">
                              Web &amp; Apps <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/web-master-services/">
                              Webmaster Services{" "}
                              <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/copy-writing/">
                              Copywriting <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/3d-solutions">
                              3D Solutions <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/creative-assets">
                              Creative Assets{" "}
                              <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/video-marketing/">
                              Video Marketing{" "}
                              <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-img">
                      <img src={img1} alt="Strategic storytelling" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="floating-casestudy">
                <a onClick={handleCaseStudyOneOpen}>
                  <div className="casestudy">
                    <div className="case-wrap">
                      {/* <div className="title"> Case study </div> */}
                      <p>
                        An exploration of Amex's business line of credit
                        promotion
                      </p>
                      <a onClick={handleCaseStudyOneOpen}>
                        View more <i className="ptwo ptwo-arrow"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              className="homeparallax middle"
              style={{
                backgroundImage: `url(${bg1})`,
              }}
            >
              <div className="holder">
                <div className="container">
                  <div className="content-wrapper">
                    <div className="left-content">
                      <div className="animation-text">
                        <div>
                          <h2>
                            Convert audiences. <br />
                            Maximize ROI.
                          </h2>
                        </div>
                        <div className="decp">
                          <p>
                            We combine AI and strategy to optimize ad campaigns,
                            run tests and continuous experimentation to drive
                            greater short-term efficiency and long-term revenue
                            growth for our clients.
                          </p>
                        </div>
                        <div className="logo-wrap">
                          <div className="each-logo">
                            <img src={Amex} alt="Amex" />
                          </div>
                          <div className="each-logo">
                            <img src={Cohere} alt="Macroair" />
                          </div>
                          <div className="each-logo">
                            <img src={Airbnb} alt="Airbnb" />
                          </div>
                        </div>
                        <div className="page-links">
                          <div className="each-page-links">
                            <a href="/paid-marketing">
                              Paid Marketing <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/seo">
                              SEO <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/marketing-automation">
                              Marketing Automation{" "}
                              <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/account-based-marketing/">
                              Account-based Marketing{" "}
                              <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-img">
                      <img
                        src={img2}
                        alt="Converting Audience"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="floating-casestudy">
                <a href="#" onClick={handleCaseStudyTwoOpen}>
                  <div className="casestudy">
                    <div className="case-wrap">
                      {/* <div className="title">Case study</div> */}
                      <p>
                        Reimagining Cohere's enterprise outreach: a success
                        story
                      </p>
                      <a href="#" onClick={handleCaseStudyTwoOpen}>
                        View more <i className="ptwo ptwo-arrow"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              className="homeparallax"
              style={{ backgroundImage: `url(${bg2})` }}
            >
              <div className="holder">
                <div className="container">
                  <div className="content-wrapper">
                    <div className="left-content">
                      <div className="animation-text">
                        <div>
                          <h2>Strategic Digital Consulting</h2>
                        </div>
                        <div className="decp">
                          <p>
                            We help align your future goals, resources, and
                            teams. With our digital expertise, gain actionable
                            insights, unlock revenue potential through CRO, and
                            accelerate decision-making with precise data
                            analysis. Revolutionize your business growth.
                          </p>
                        </div>
                        <div className="logo-wrap">
                          <div className="each-logo">
                            <img src={GD} alt="Gentel Dental" />
                          </div>
                          <div className="each-logo">
                            <img src={Acalvio} alt="Acalvio" />
                          </div>
                          <div className="each-logo">
                            <img src={OpenPrise} alt="OpenPrise" />
                          </div>
                        </div>
                        <div className="page-links">
                          <div className="each-page-links">
                            <a href="/strategic-planning/">
                              Strategic Planning{" "}
                              <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/cro">
                              Conversion Rate Optimization{" "}
                              <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                          <div className="each-page-links">
                            <a href="/marketing-analytics">
                              Audit, Reporting, Analytics &amp; Attribution{" "}
                              <i className="ptwo ptwo-arrow"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-img">
                      <img
                        src={img3}
                        alt="Engage Convert"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="floating-casestudy">
                <a href="/CRO-Case-Study.pdf" target="_blank">
                  <div className="casestudy">
                    <div className="case-wrap">
                      <p>
                        Enhancing Amex brand awareness by ~45% with winning
                        landing pages.
                      </p>
                      <a href="/CRO-Case-Study.pdf" target="_blank">
                        Read More <i className="ptwo ptwo-arrow"></i>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          {isCaseStudyOneOpen && (
            <div class="video-popup">
              <ReactImageVideoLightbox
                data={[
                  {
                    url: "https://player.vimeo.com/video/927918905",
                    type: "video",
                    title: "Amex",
                  },
                ]}
                startIndex={0}
                showResourceCount={false}
                onCloseCallback={handleCaseStudyOneClose}
              />
            </div>
          )}
          {isCaseStudyTwoOpen && (
            <div class="video-popup">
              <ReactImageVideoLightbox
                data={[
                  {
                    url: "https://player.vimeo.com/video/927918846",
                    type: "video",
                    title: "Cohere",
                  },
                ]}
                startIndex={0}
                showResourceCount={false}
                onCloseCallback={handleCaseStudyTwoClose}
              />
            </div>
          )}
          <div className="three-column-wrap">
            <div className="three-column">
              <div className="container">
                <div className="title-descrition-section">
                  <div>
                    <p className="thumbnail">Our product</p>
                  </div>
                  <h2>Arena</h2>
                  <p>
                    Our enterprise-grade analytics dashboard supports you in
                    maximizing insights. With our experts handling data
                    integration, dashboard creation and customization, we
                    incorporate new sources so you can achieve your analytics
                    vision and potential.
                  </p>
                </div>
                <div className="column-wrapper">
                  <div className="each-benefit">
                    <div className="img-wrap">
                      <img src={Artboard} alt="Art board" />
                    </div>
                    <p>Cross-platform Analytics</p>
                  </div>
                  <div className="each-benefit">
                    <div className="img-wrap">
                      <img src={dataIntegration} alt="Data integration" />
                    </div>
                    <p>Data Integration</p>
                  </div>
                  <div className="each-benefit">
                    <div className="img-wrap">
                      <img src={RichDataInsights} alt="Rich data insights" />
                    </div>
                    <p>Rich Data Insights</p>
                  </div>
                </div>
                <div className="cta">
                  <a target="" href="/arena-calibrate/">
                    Explore
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="InnerSlider">
            <div className="slider-wrapper">
              {/* <Slider {...settings} className="inner-slider">
                <div className="each-slider">
                  <div className="banner-cover">
                    <picture>
                      <source
                        media="(max-width: 991.98px)"
                        srcset="https://dev-upgradep2.pantheonsite.io/wp-content/uploads/2021/12/bellhop-casestudy-m-banner-image.jpeg"
                        alt="Bellhops in decreasing their CPL by 57% and increase their conversions by 3.75X."
                      />
                      <img
                        src="https://dev-upgradep2.pantheonsite.io/wp-content/uploads/2021/12/bellhop-casestudy-banner-image.jpeg"
                        alt="Bellhops in decreasing their CPL by 57% and increase their conversions by 3.75X."
                      />
                    </picture>
                  </div>
                  <div className="slider-content">
                    <div className="container">
                      <div className="content-wrap">
                        <h2>The Cover Story</h2>
                        <div className="slider-logo">
                          <img
                            src="https://dev-upgradep2.pantheonsite.io/wp-content/uploads/2024/02/American-Express.png"
                            alt="American-Express"
                          />
                        </div>
                        <p>
                          This campaign was a launch platform for a laptop that
                          lets you work without opening the lid, helping you Get
                          Time on Your Side—which became our slogan.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="each-slider">
                  <div className="banner-cover">
                    <picture>
                      <source
                        media="(max-width: 991.98px)"
                        srcset="https://dev-upgradep2.pantheonsite.io/wp-content/uploads/2021/12/bellhop-casestudy-m-banner-image.jpeg"
                        alt="Bellhops in decreasing their CPL by 57% and increase their conversions by 3.75X."
                      />
                      <img
                        src="https://dev-upgradep2.pantheonsite.io/wp-content/uploads/2021/12/bellhop-casestudy-banner-image.jpeg"
                        alt="Bellhops in decreasing their CPL by 57% and increase their conversions by 3.75X."
                      />
                    </picture>
                  </div>
                  <div className="slider-content">
                    <div className="container">
                      <div className="content-wrap">
                        <h2>What our clients say</h2>
                        <div className="slider-logo">
                          <img
                            src="https://dev-upgradep2.pantheonsite.io/wp-content/uploads/2024/02/Bellhops.png"
                            alt="Bellhops"
                          />
                        </div>
                        <p>
                          Our enterprise-grade analytics dashboard supports you
                          in maximizing insights. With our experts handling data
                          integration, dashboard creation and customization, we
                          incorporate new sources so you can achieve your
                          analytics vision and potential.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider> */}
              {/* <video
                
                width="100%"
                playsInline="playsinline"
                muted="muted"
                preload="yes"
                autoPlay="autoplay"
                loop="loop"
                className="video-js BiggerScreen"
                data-setup='{"controls": false, "autoplay": true, "preload": "true", "loop":true }'
              >
                <source
                  src="https://www.position2.com/footer-video.mp4"
                  type="video/mp4"
                />
              </video> */}
            </div>
          </div>
          <div id="Cards">
            <div className="container">
              <h2>Insights & trends</h2>
              <div className="cards-wrap">
                <a
                  className="each-card"
                  href="https://www.position2.com/blog/beyond-the-hype-ais-actual-impact-on-four-key-industries/"
                >
                  <div className="bckImage">
                    <img
                      alt="How digital marketers can drive growth"
                      src={blogthum1}
                    />
                  </div>
                  <div className="textOverlay">
                    <div className="eyeBrow">
                      <span>Blog</span>
                    </div>
                    <div className="title">
                      <h5>
                        Beyond the Hype: AI’s Actual Impact on Four Key
                        Industries
                      </h5>
                    </div>
                    <div className="time">
                      <p>March 22, 2024</p>
                    </div>
                  </div>
                </a>

                <a
                  href="https://www.position2.com/blog/top-5-barriers-to-proactive-ai-adoption/"
                  className="each-card"
                >
                  <div className="bckImage">
                    <img alt="AI impact on marketing" src={blogthum2} />
                  </div>
                  <div className="textOverlay">
                    <div className="eyeBrow">
                      <span>Blog</span>
                    </div>
                    <div className="title">
                      <h5>Top 5 Barriers to Proactive AI Adoption</h5>
                    </div>
                    <div className="time">
                      <p>March 14, 2024</p>
                    </div>
                  </div>
                </a>
                <a
                  href="https://www.position2.com/blog/why-is-it-important-to-optimize-your-landing-pages/"
                  className="each-card"
                >
                  <div className="bckImage">
                    <img alt="Redefining business with AI" src={blogthum3} />
                  </div>
                  <div className="textOverlay">
                    <div className="eyeBrow">
                      <span>Blog</span>
                    </div>
                    <div className="title">
                      <h5>
                        Why is it important to optimize your landing pages?
                      </h5>
                    </div>
                    <div className="time">
                      <p>March 13, 2024</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <section className="awards-section parallax">
            <div className="awards">
              <div className="container">
                <div className="wrap">
                  <div className="img-one image-shape">
                    <img alt="one" src={awards1} />
                  </div>
                  <div className="img-two image-shape">
                    <img alt="two" src={awards2} />
                  </div>
                  <div className="img-three image-shape">
                    <img alt="three" src={awards3} />
                  </div>
                  <div className="img-four image-shape">
                    <img alt="four" src={awards4} />
                  </div>
                  <div className="title image-shape">
                    <h3>Awards</h3>
                  </div>
                </div>
                <ul>
                  <li>
                    <img src={DesignAwards} alt="Logo" />
                  </li>
                  <li>
                    <img src={IAC} alt="Logo" />
                  </li>
                  <li>
                    <img src={BigBang} alt="Logo" />
                  </li>
                  <li>
                    <img src={WMA} alt="Logo" />
                  </li>
                  <li>
                    <img src={Iamia} alt="Logo" />
                  </li>
                  <li>
                    <img src={Webawards} alt="Logo" />
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div id="InnerSlider" className="home-testimonial">
            <div className="slider-wrapper">
              <div className="inner-slider">
                <div className="each-slider">
                  <div className="banner-cover">
                    <video
                      width="100%"
                      playsInline="playsinline"
                      muted="muted"
                      preload="yes"
                      autoPlay="autoplay"
                      loop="loop"
                      className="video-js BiggerScreen"
                      data-setup="{'controls': false, 'autoplay': true, 'preload': true, 'loop':true }"
                    >
                      <source
                        src="https://www.position2.com/footer-video.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <div className="slider-content">
                    <div className="container">
                      <div className="content-wrap">
                        <p className="eyebrow">Recent work</p>
                        <h2>The Cover Story</h2>
                        <p>
                          This campaign was a launch platform for a laptop that
                          lets you work without opening the lid, helping you Get
                          Time on Your Side—which became our slogan.
                        </p>
                        <a
                          target="_blank"
                          href="https://www.position2studios.com/thinkbookpluslaunch"
                        >
                          View campaign assets
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="homebutton">
            <div className="container">
              <div>
                <div>
                  <p>Ready to accelerate your results?</p>
                </div>
                <a className="red-btn" href="/contact-us">
                  Let's Talk Growth
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

HomePage.propTypes = {
  siteTitle: PropTypes.string,
}

HomePage.defaultProps = {
  siteTitle: ``,
}

export default HomePage
